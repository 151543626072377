* {
    box-sizing: border-box;
  }
  
  :root {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  
    color: #212122;
    background-color: #F5F5F5;
  
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  
  }
  
  body {
    padding: 0;
    margin: 0;    
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .main {
    margin: 0;
    padding: 1rem;
    text-align: center;
    background-color: white;    
    width: auto;
    min-height: 100dvh;    
  }
  
  .btn-primary {  
    border-radius: 12px;
    background-color: #4062A3;
  }
  
  .btn-primary:hover {  
    border-radius: 12px;
    background-color: #4062A3;
  }
  
  .btn-primary:focus {  
    border-radius: 12px;
    background-color: #4062A3;
  }
  
  .box {
    border-radius: 12px;
    border: 1px solid #D1D2D3;
    
  }
  
  .box h1 {  
    font-size: 17pt;
    color: #212122;
  }
  .box-content {
    cursor: pointer;
    display: flex;
    color: #4062A3;
    font-weight: bold;
    font-size: 32pt;
  }
  
  .button-box {
    cursor: pointer;
    border-radius: 12px;
    background-color: #4062A3;
    color: #FFFFFF;
  }
  
  .button-box h1 {
    display: flex;
    border-radius: 12px;
    font-size: 20pt;  
    color: #FFFFFF;
  }
  
  .button-box-abmelden h1 {
    display: flex;
    border-radius: 12px;
    font-size: 20pt;
    color: #FFFFFF;
  }
  
  .button-box-abmelden {  
    cursor: pointer;
    border-radius: 12px;
    background-color: #7B869C;
    color: #FFFFFF;
  }
  
  .button-box-content {
    display: flex;  
    font-style: italic;
    font-weight: lighter;
    font-size: 14pt;
  }
  
  .nav-pills li a,
  .nav-pills li a:hover{
    display: flex;
    color: #FFF;
  }
  
  
  .nav-pills li a:hover {
    background-color: #4062A3;
  }
  
  .nav-pills {
    --bs-nav-pills-link-active-bg: #4062A3;
  }
  
  
  .tab ul {
    list-style-type: none;
    
  }
  
  .tab ul li {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-right: 2rem;
    margin-left: 2rem;
    display: inline;
    font-weight: bold;
    color: #616161;
    text-align: center;
    background-color: transparent;
  }
  
  .tab ul li.active {
    display: inline;
    color: #4062A3;
    font-weight: bold;
    text-align: center;
    background-color: white;
  }
  
  
  .tab ul li:hover {
    color: #4062A3;
    cursor: pointer;
    background-color: white;
  }
  
  .content {
    display: none;
  }
  
  .show-content {
    display: block;
  }
  
  .form-wrapper {
    border-radius: 12px;
    background-color: #fff;
    padding: 3rem;
  }
  
  .form-wrapper-content {  
    border: 1px solid #EDEDED;
    border-radius: 12px;
    background-color: #fff;
    padding: 3rem;
  }
  
  .form-wrapper h1 {  
    font-size: 12pt;
    font-style: italic;
    color: #B9B9B9;
  }
  
  .form-wrapper-content h1 {
    font-size: 12pt;
    font-style: italic;
    color: #919191;
  }
  
  .form-wrapper-content-button {
    cursor: pointer;
    color: #7E8590;
  }
  
  .form-wrapper-content-button.active{
    color: #FFF;  
    background-color: #4062A3;
  }
  
  .company-select {
    cursor: pointer;
    background-color: #E6E6E6;
    color: #FFF;
    border-radius: 20px;
  }
  
  .origin-indicator {  
    min-width: .5rem;
    height: 100%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .form {    
    max-width: 40rem;
    width:50%;
    margin: auto;
    border: 1px solid #D1D2D3;
    border-radius: 15px;
    padding: 2rem;
    text-align: center;
  }
  
  .form label,
  .form input,
  .form textarea {
    display: block;
    width: 100%;
  }
  
  .form input,
  .form textarea{
    font: inherit;
    padding: 0.25rem;
    border-radius: 12px; 
    border: none;   
    box-shadow: 2px 2px 10px #888888;
  }

  .actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
  }

  .form h1 {
    color: #616161;
    font-size: 18pt;
  }
 

.header {
  padding: 1rem;
  display: flex;
  color: #888B95;
  justify-content: center;
  background-color: #393D4E;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 1rem;
  display: flex;
  color: #888B95;
  justify-content: center;
  background-color: #393D4E;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - (200px + 100px));
  padding: 30px;
  box-sizing: border-box;
}

.nav .nav-link {
  color: #464F60;
}

.nav .nav-link .round-button{
  color: black;
  background-color: #fff;
}

.nav .nav-link:hover {
  background-color: white;
  color: #000;
}

.nav .nav-link.active {
  color: black;  
  background-color: white;
  font-weight: bold;
  padding: 0;    
  border-radius: 50px; 
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.nav .nav-link.active .round-button {
  background-color: #464F60;
  color: white;  
}

.button {    
  padding: 0;    
  border-radius: 50px; 
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.round-button {
  margin: 0;
  border-radius: 50%; 
  color: white;
  background-color: #3A3A3A;
  font-size: 18pt
}