.header {    
    width: auto;
    height: 75dvh;
    margin: 1rem;
    margin-top: 0;        
    display: flex;    
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  